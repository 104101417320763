import React from "react";
import { Sidebar as PrimeSidebar } from "primereact/sidebar";
import { useSidebar } from "./SidebarContext";
import { Button } from "primereact/button";
import { useLogin } from "../Screens/Login/LoginContext";
import { useLocation, useNavigate } from "react-router-dom";
import { Theme } from "../Theme/Theme";
import SideBaritem from "./SideBaritem";
import { SlGraph } from "react-icons/sl";
import { LuNewspaper } from "react-icons/lu";
import { BsJournalText } from "react-icons/bs";
import { MdOutlineEmail } from "react-icons/md";
import { IoSettingsOutline } from "react-icons/io5";
import { RiLockPasswordLine } from "react-icons/ri";
import { AiOutlineInfoCircle } from "react-icons/ai";

const Sidebar = () => {
  const { logout } = useLogin();
  const navigate = useNavigate();
  const location = useLocation();

  const Logout = () => {
    localStorage.clear();
    logout();
    navigate("/");
  };
  const { visible, hideSidebar } = useSidebar();

  return (
    <PrimeSidebar
      showCloseIcon={false}
      style={{ borderStyle: "solid", borderWidth: 0.5 }}
      visible={visible}
      onHide={hideSidebar}
    >
      {/* <div style={{background:"white"}}></div> */}
      <div
        style={{
          display: "flex",
          padding: 10,
          borderBottomStyle: "solid",
          paddingBottom: 20,
          borderWidth: 0.5,
        }}
      >
        <div style={{ width: 60, height: 60 }}>ICON</div>
        <div>
          <div style={{ fontSize: 14, fontWeight: "bold" }}>DFC</div>
          <div
            style={{
              fontSize: 14,
              color: Theme.MEDIUMGRAY,
              textTransform: "uppercase",
              fontWeight: "bold",
            }}
          >
            {JSON.parse(localStorage.getItem("client_details")).username}
          </div>
          <div
            onClick={() => {
              navigate("/manage");
              hideSidebar();
            }}
            style={{
              fontSize: 14,
              color: Theme.FONTCOLOR,
              marginTop: 20,
              fontWeight: "bold",
            }}
          >
            Manage Accounts
          </div>
        </div>
      </div>
      <SideBaritem
        Icon={SlGraph}
        size={25}
        name={"Trade"}
        path={"/trade"}
        selected={location.pathname === "/trade"}
      />
      <SideBaritem
        Icon={LuNewspaper}
        size={22}
        name={"News"}
        path={"/news"}
        selected={location.pathname === "/news"}
      />
      <SideBaritem
        Icon={MdOutlineEmail}
        size={25}
        name={"MailBox"}
        path={"/mailbox"}
        selected={location.pathname === "/mailbox"}
      />
      <SideBaritem
        Icon={BsJournalText}
        size={22}
        name={"Journal"}
        path={"/journal"}
        selected={location.pathname === "/journal"}
      />
      <SideBaritem
        Icon={IoSettingsOutline}
        size={22}
        path={"/setting"}
        selected={location.pathname === "/setting"}
        name={"Setting"}
      />
      <SideBaritem
        Icon={RiLockPasswordLine}
        size={22}
        name={"Change Password"}
        path={"/password"}
        selected={location.pathname === "/password"}
      />
      <SideBaritem Icon={AiOutlineInfoCircle} size={25} name={"About Us"} />

      {/* <Button label='LOGOUT' onClick={Logout}/> */}
    </PrimeSidebar>
  );
};

export default Sidebar;
