import React, { useEffect, useRef, useState } from 'react'

import { useWindowSize } from '@react-hook/window-size'
import TradeTopBar from './TradeTopBar'
import { Theme } from '../../Theme/Theme'
import { HiDotsHorizontal } from "react-icons/hi";
import TradeItemCard from './TradeItemCard';
import { Sidebar } from 'primereact/sidebar';
import Botttombar from '../../BottomBar/Botttombar';
import { useTradeContext } from './TradeContext';
import { Menu } from 'primereact/menu';
import { apiCall } from '../Backend/Api';
import Orders from '../History/Orders';
import { useNavigate } from 'react-router-dom';

export default function Trade() {
    const [width, height] = useWindowSize()
    const [visibleBottom, setVisibleBottom] = useState(false);

    const menuLeft = useRef(null);
    const navigate = useNavigate()


    const {trades, loadTrades} = useTradeContext()


const OnclickTrade =(e)=>{

  localStorage.setItem('script',JSON.stringify(e))

  navigate("/buysell")
}


const Handle_Sq_Off = async(e,d)=>{

    const yesno =  window.confirm("Are You Sure Want "+d)

    if(yesno){


      let path = "/";
      
      switch (e) {
        case "0":
          path = "/sq_off_all"; // Define the path for case 0
          break;
        case "1":
          path = "/sq_off_profit"; // Example for another case
          break;
        // Add more cases as needed
        case "2":
          path = "/sq_off_loss"; // Example for another case
          break;
        
      }



      const response = await apiCall(path)
      // alert(JSON.stringify(response))

    
 
    }

   

 }
    


    const items = [
      {
          items: [
              {
                  label: 'Close All Trades',
                  command:()=>{
                    Handle_Sq_Off('0','Close All Trades')
                  }
                

                
              },
              {
                  label: 'Close All Profit',
                  command:()=>{
                    Handle_Sq_Off('1','Close All Profit')
                  }
                 
                
              },
              {
                  label: 'Close All Loss',
                  command:()=>{
                    Handle_Sq_Off('2','Close All Loss')
                  }
                 
              },
             
          ]
      }
  ];



  useEffect(()=>{
    loadTrades()
  },[])
  return (

    <div style={{display:'flex',flexDirection:'column',height:height}}>


    <TradeTopBar />
    
    <div style={{
        padding:5,background:Theme.LIGHTGRAY,borderStyle:'solid',borderWidth:0,borderTopWidth:0.5,borderBottomWidth:0.5,borderColor:Theme.MEDIUMGRAY,display:'flex',
        color:Theme.DARKGRAY

        }}>
            <div style={{flex:1}}>Positions</div>
            <div
            onClick={(event) => menuLeft.current.toggle(event)}
            style={{height:20,display:'flex',justifyContent:'center',alignItems:'center'}}
            
            
            ><HiDotsHorizontal  size={25}/></div>
            </div>
    <div style={{flex:1,overflowY:'scroll'}}>

      {trades&&trades.map((i,t)=>{
        if(t==0){
          localStorage.setItem('script',JSON.stringify(i))
        }
        return(

 <TradeItemCard openwindow ={()=>OnclickTrade(i)}  key={i.id} item={i}/>
      )})
           
    }
    
     <div style={{
        padding:5,background:Theme.LIGHTGRAY,borderStyle:'solid',borderWidth:0,borderTopWidth:0.5,borderBottomWidth:0.5,borderColor:Theme.MEDIUMGRAY,display:'flex',
        color:Theme.DARKGRAY

        }}>
            <div style={{flex:1}}>Orders</div>
            <div
            onClick={(event) => menuLeft.current.toggle(event)}
            style={{height:20,display:'flex',justifyContent:'center',alignItems:'center'}}
            
            
            >
            </div>
            </div>
      <Orders notop={true}/>
    </div>


    <Sidebar visible={visibleBottom} showCloseIcon={false} position="bottom" style={{padding:0}} onHide={() => setVisibleBottom(false)}>
   <div style={{fontSize:22,fontWeight:'bold',paddingTop:10}}>SUNPHARMA<span style={{marginLeft:10,fontSize:12}}>NSE</span></div>
    </Sidebar>
    <Botttombar active={"TRADE"}/>

    <Menu model={items} popup ref={menuLeft} style={{width:'auto',paddingBottom:0}} id="popup_menu_right" />
    </div>
  )
}
