import React from 'react'

import { PrimeReactProvider } from 'primereact/api';
// import "primereact/resources/themes/arya-blue/theme.css";
import 'primeicons/primeicons.css';



import { SidebarProvider } from './Sidebar/SidebarContext';
import {NotificationProvider} from './Notification/NotificationContext'


import { LoginProvider } from './Screens/Login/LoginContext';
import { BrowserRouter as Router, useNavigate } from 'react-router-dom';
import AppRoutes from './Screens/Routes/AppRoutes';
import Notification from './Notification/Notification';

import { SocketProvider } from './Socket/SocketContext';


export default function App() { 
// localStorage.clear()
  return (
    <PrimeReactProvider>
    <NotificationProvider>
    
    
   
      <SocketProvider>
   
    <LoginProvider>
      <SidebarProvider>
     
        <Notification/>
        <Router>
          {/* <Sidebar /> */}
          <AppRoutes />
        </Router>
       
      </SidebarProvider>
      
      
    </LoginProvider>
    </SocketProvider>

   
  
 
  </NotificationProvider>
  </PrimeReactProvider>
  )
}

