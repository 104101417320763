import React, { useEffect, useMemo, useState } from "react";
import { Theme } from "../../Theme/Theme";
import { FaLongArrowAltRight } from "react-icons/fa";
import { useSocket } from "../../Socket/SocketContext";
import { useTradeContext } from "./TradeContext";

export default function TradeItemCard({openwindow,item}) {
  const { subscribeToSymbol,socket } = useSocket();
  const [scriptData,setScriptdata] = useState({})


 const scriptId = item.script_id;


const handleTrade = (data)=>{
  setScriptdata(data)

}
const handleBidask =(data)=>{
  setScriptdata({...scriptData,Bid:data.Bid,Ask:data.Ask})
}


  // const scriptData = data[scriptId] || {};


useEffect(()=>{

  socket.on(`trade${scriptId}`, handleTrade);
  socket.on(`bidask${scriptId}`, handleBidask);


  socket.emit("giverate",scriptId)


  return () => {
    socket?.off(`trade${scriptId}`, handleTrade);
    socket?.off(`bidask${scriptId}`, handleBidask);
  };
},[ item.script_id])

const {updateProfitLoss} =useTradeContext()
 

const current = useMemo(() => {

  
    if (scriptData && item.script_id )  {
      const {Ask,Bid,LTP}= scriptData


      return item.buy_sell === 1 ? Ask>0?Ask:LTP : Bid>0?Bid:LTP;
    }
    return null;
  }, [scriptData, item.script_id, item.buy_sell]);



  const pfl = useMemo(() => {
    
    if (!scriptData || !item) return 0;

    let m2m = 0;
    let ratediffrance = 0;
    let currentrate = 0;
    let brokerage = 0;

    if (item.buy_sell == 0) {
      currentrate =current;
    
      ratediffrance = currentrate - item.rate;

      
    } else {
      currentrate = current;
      ratediffrance = item.rate - currentrate;
    }

    m2m = ratediffrance * item.qty;

    brokerage = (item.brokerage * currentrate*item.qty) / 100;
    

    updateProfitLoss(item.script_id,(m2m - brokerage).toFixed(2),m2m)
    return (m2m - brokerage).toFixed(2);


  }, [scriptData, item.script_id, item.buy_sell, item.rate, item.qty, item.brokerage]);
 
  return (
    <div
      style={{
        // padding: 5,
        // paddingLeft: 10,
        // paddingRight: 10,
        borderWidth: 0,
        borderStyle: "solid",
        borderBottomWidth: 0.5,
        borderColor: Theme.MEDIUMGRAY,
      }}
    >
      
      <div
      onClick={openwindow}
        
        style={{ display: "flex" }}
      >
      <div style={{width: 3,background:item.pending_msg=="1"?'unset':Theme.FONTCOLOR}}></div>
        <div style={{ flex: 1,padding:5,paddingLeft:10 }}>
          <div style={{ display: "flex", fontSize: 16 }}>
            <div>{item.symbol}</div>
            <div
              style={{ marginLeft: 5, color: item.buy_sell==0? Theme.FONTCOLOR:Theme.REDCOLOR, fontSize: 14 }}
            >
              {item.buy_sell == 0 ?"buy":'sell'} <span style={{ marginLeft: 2 }}>{item?.qty}</span>
            </div>
          </div>
          <div style={{ display: "flex", color: Theme.DARKGRAY,fontSize:14 }}>
            <div>{item.rate}</div>
            <div
              style={{
                height: 17,
                marginLeft: 5,
                marginRight: 5,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FaLongArrowAltRight />
            </div>
            <div>{current}</div>
          </div>
        </div>
        <div style={{ color:pfl>=0? Theme.FONTCOLOR:Theme.REDCOLOR, fontSize: 18,paddingRight:10}}>{pfl}</div>
      </div>

    </div>
  );
}
